<template>
  <div id="editprofile" class="tab-pane active tab-box">
    <div class="mt-4">
      <div class="mt-4 position-relative">
        <h6 class="mb-2">{{ $t('Name') }}</h6>
        <input class="form-control mb-0 h50" type="text" :placeholder="$t('Enter Full Name')" aria-label="Name"
          v-model="$v.profile.user_name.$model" :class="{ 'is-invalid': $v.profile.user_name.$error, }" />
        <div v-if="$v.profile.user_name.$error" class="invalid-feedback">
          <span v-if="!$v.profile.user_name.required">{{ $t('Please enter field', { msg: $t('Name') }) }}</span>
        </div>
      </div>
      <div class="mt-4 position-relative">
        <h6 class="mb-2">{{ $t('Email Address') }}</h6>
        <input class="form-control mb-0 h50" type="text" :placeholder="$t('Email')" aria-label="Email"
          v-model="$v.profile.user_email.$model" :class="{
            'is-invalid': $v.profile.user_email.$error,
          }" />
        <div v-if="$v.profile.user_email.$error" class="invalid-feedback">
          <span v-if="!$v.profile.user_email.required">{{ $t('Please enter email') }}</span>
          <span v-if="!$v.profile.user_email.email">{{ $t('This value should be a valid email') }}</span>
        </div>
      </div>
      <input type="hidden" v-model="profile.country_code">
      <div class="mt-4 position-relative">
        <h6 class="mb-2">{{ $t('Phone Number') }}</h6>
        <input class="form-control mb-0 h50" type="text" placeholder="Phone" aria-label="Phone"
          v-model="profile.mobile_number" readonly />
      </div>
    </div>
    <!-- <div class="mt-4 position-relative" v-if="languageArray.length > 1">
      <h6 class="mb-2">{{ $t('Select Language') }}</h6>
      <select v-model="profile.is_langauge" class="custom-select" :class="'ccc' + profile.is_langauge">
        <option v-for="lang in languageArray" :key="lang.value"
          :class="lang.value == $store.state.lang.locale ? 'selected' : ''" :value="lang.value"
          :selected="lang.value == $store.state.lang.locale ? 'selected' : ''">{{ lang.text }}
        </option>
      </select> -->
    <!-- <select class="custom-select" @change="languageChange" v-model="language" >
            <option :value="l.value" v-for="l in languages" :key="l.value">{{l.text}}</option>
          </select> -->
    <!-- </div> -->
    <div class="deliveryaddress mt-5 profile-bottom border-top pt-3 d-flex">
      <div class="full-btn px-3 col" id="editprofile">
        <button @click="editProfile">{{ $t('Update') }}</button>
      </div>
      <!-- <div class="full-btn px-3 col" data-bs-toggle="modal" data-bs-target="#deleteProfileModal" id="deleteprofile">
        <button>{{ $t('Delete') }}</button>
      </div> -->
    </div>
    <!-- Button trigger modal -->
    <!-- <button type="button" class="btn btn-primary" >
      Launch demo modal
    </button> -->

    <!-- Modal structure -->
    <!-- <div class="modal fade" id="deleteProfileModal" tabindex="-1" aria-labelledby="deleteProfileModalLabel"
      aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="deleteProfileModalLabel">{{ $t('Delete Profile') }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p>{{ $t('Are you sure you want to delete your profile?') }}</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              {{ $t('Cancel') }}
            </button>
            <button type="button" class="btn btn-danger" @click="confirmDelete">
              {{ $t('OK') }}
            </button>
          </div>
        </div>
      </div>
    </div> -->

    <!-- Modal with custom class for width -->
    <!-- <div class="modal fade" id="deleteProfileModal" tabindex="-1" aria-labelledby="deleteProfileModalLabel"
      aria-hidden="true">
      <div class="modal-dialog custom-modal-width">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="deleteProfileModalLabel">{{ $t('Delete Profile') }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p>{{ $t('Are you sure you want to delete your profile?') }}</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t('Cancel') }}</button>
            <button type="button" class="btn btn-danger" @click="deleteProfile">{{ $t('OK') }}</button>
          </div>
        </div>
      </div>
    </div> -->

  </div>

</template>

<script>
import { mapActions } from 'vuex';
import { required, email } from "vuelidate/lib/validators";
import { getLanguageText } from '../../helper/helper';

export default {
  data() {
    return {
      profile: {
        user_email: "",
        user_name: "",
        mobile_number: "",
        country_code: "",
        is_langauge: "en"
      },
      prim_lang: '',
      seco_lang: '',
      language: '',
      languageArray: [],

    };
  },
  computed: {
    lang: {
      get: function () {
        return this.$store.state.lang.locale
      },
      set: function (newVal) {
        this.$store.dispatch('lang/changeLangLocale', newVal)
      }
    }
  },
  validations: {
    profile: {
      user_email: {
        // required,
        email,
      },
      user_name: {
        required,
      },
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.$auth.user && this.$auth.setting) {
        this.profile = {
          user_email: this.$auth.user.user_email,
          user_name: this.$auth.user.user_name,
          mobile_number: this.$auth.user.mobile_number,
          country_code: this.$auth.user.country_code,
          is_langauge: this.$store.state.lang.locale
        };
        this.languageArray = [
          { 'value': this.$auth.setting.primary_lang, text: getLanguageText(this.$auth.setting.primary_lang) },
        ];
        if (this.$auth.setting.secondary_lang) {
          this.languageArray.push({ 'value': this.$auth.setting.secondary_lang, text: getLanguageText(this.$auth.setting.secondary_lang) });
        }
        this.prim_lang = this.$auth.setting.primary_lang,
          this.seco_lang = this.$auth.setting.secondary_lang
      } else {
        this.$router.push({ name: "sign-in" });
      }
    }, 1000);
  },
  methods: {
    ...mapActions("user", ["updateUserDetails", "deleteUserProfile"]),

    editProfile() {
      this.$v.$touch();
      if (this.$v.profile.$invalid) {
        return;
      } else {
        this.updateUserDetails(this.profile).then((data) => {
          if (data.code == 200) {
            this.lang = this.profile.is_langauge;
            localStorage.setItem('localelang', this.profile.is_langauge);
            this.$auth.setUserDetails(data.user_details);
            this.$toasted.success(this.$t('Profile update successfully'), { duration: 2000 });
            this.$router.push({ name: 'home' });
          } else {
            this.$toasted.success(data.msg, { duration: 2000 });
          }
        });
      }
    },

    // deleteProfile() {
    //   this.deleteUserProfile({
    //     user_id: this.$auth.user.user_id,
    //     vendor_id: this.$auth.getVendorId(),
    //     type: 'customer',
    //     is_langauge: this.$store.state.lang.locale,
    //   }).then((data, callback) => {
    //     console.log(data)
    //     if (data.code == 200) {
    //       $('#deleteProfileModal').modal('hide');
    //       // this.$router.push({ name: 'home' });
    //       this.$store.commit('product/clearCartItems')
    //       localStorage.removeItem('place_order_data');
    //       this.$router.push({ name: callback }).catch(() => { });
    //       this.$toasted.error(data.msg, {
    //         duration: 2000,
    //       });
    //       return false;
    //     }
    //   })
    // },


    // deleteProfile() {
    //   if (confirm(this.$t("Are you sure you want to delete your account?"))) {
    //     this.deleteUserProfile({
    //       user_id: this.$auth.user.user_id,
    //       vendor_id: this.$auth.getVendorId(),
    //       type: 'customer',
    //       is_langauge: this.$store.state.lang.locale,
    //     }).then((data) => {
    //       if (data.code == 200) {
    //         $('#deleteProfileModal').modal('hide');

    //         // Clear relevant data (same as logout flow)
    //         this.$store.commit('product/clearCartItems');
    //         localStorage.removeItem('place_order_data');

    //         // Optionally, clear any other local storage or state values
    //         // localStorage.removeItem('vuex'); // Uncomment if necessary

    //         // Log out user and remove auth token
    //         // this.$auth.logout({
    //         //   user_id: this.$auth.user.user_id,
    //         //   devicetype: "I",
    //         //   devicetoken: this.$auth.user.token,
    //         //   unquie_id: "",
    //         //   vendor_id: this.$auth.getVendorId(),
    //         //   is_langauge: this.$store.state.lang.locale,
    //         // }, () => {
    //         //   // Redirect to home page after successful account deletion
    //         //   this.$router.push({ name: 'home' }).catch(() => { });

    //         //   // Show success message
    //         //   this.$toasted.success(data.msg, {
    //         //     duration: 2000,
    //         //   });
    //         // });
    //       } else {
    //         // Show error message if the delete request fails
    //         this.$toasted.error(data.msg, {
    //           duration: 2000,
    //         });
    //       }
    //     });
    //   }
    // },



    languageChange() {
      localStorage.setItem('language', this.language);
    },

  },
};
</script>

<style scoped>
.custom-modal-width .modal-dialog {
  max-width: 800px;
  /* Set your desired width */
}

.modal-content {
  height: auto !important;
}
</style>